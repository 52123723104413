import React, { useState } from "react"

import { Container, TextInput, Icon } from "../../../"

import css from "./style.module.scss";

import OfferCard from "../offer-card"

import { Button as AntdButton, Collapse } from "antd";

const handleInputChange = event => {
    const target = event.target
    const value = target.value
    return value;
}

const { Panel } = Collapse;

const metalLevelColors = {
    Bronze: "#6A3805",
    Catastrophic: "#575988",
    Gold: "#AF9500",
    Platinum: "#3B3B41",
    Silver: "#B4B4B4"
}

export default (props) => {
    const [name, setName] = useState()

    const [email, setEmail] = useState()

    const [phone, setPhone] = useState()

    const { results, selectedItems, sendQuote } = props;

    const medicalPlans = results.plans["MEDICAL"];
    const dentalPlans = results.plans["DENTAL"];



    return <>{selectedItems.size > 0 && <Container className={css.thirdStep}>
        <Container className={css.cardHeader}>Your Cart</Container>

        <Container className={css.finalFormContainer}>
            <Container className={css.finalFormSummary}>
                {selectedItems && [...selectedItems].some(s => medicalPlans.some(d => d.id == "" + s)) && <Container className={css.planTypeContainer}>
                    <Container className={css.planType}>Medical</Container>
                    <Collapse ghost >{
                        [...selectedItems].map(s => {
                            const plan = medicalPlans.filter(f => f.id == "" + s)[0];


                            if (!plan) {
                                return null;
                            }
                            const { info } = plan;
                            let iconProps;
                            if (info && info.label == "Metal Level") iconProps = { icon: "star-flag", fill: metalLevelColors[info.value] };
                            if (info && info.value == "Association") iconProps = { icon: "association", fill: "#F7971C" };
                            if (info && info.value == "NHICSupplemental") iconProps = { icon: "shield-filled", fill: "#F7971C" };
                            if (info && info.value == "STM") iconProps = { icon: "shield-outlined", fill: "#F7971C" };
                            if (info && info.value == "Basic") iconProps = { icon: "s-outlined", fill: "#1B75BC" };
                            if (info && info.value == "Crown") iconProps = { icon: "crown", fill: "#1B75BC" };
                            if (info && info.value == "Enhanced") iconProps = { icon: "s-filled", fill: "#1B75BC" };
                            if (info && info.value == "Short Term Health Insurance") iconProps = { fill: "#575988", icon: "short-term" };
                            if (info && info.value == "Dental Insurance") iconProps = { icon: "dental-insurance", fill: "#053555" };
                            if (info && info.value == "HealthiestYou") iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
                            if (info && info.value == "Hospital Indemnity") iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
                            if (info && info.value == "Supplemental Indemnity") iconProps = { icon: "supplemental", fill: "#F3BF38" };
                            if (info && info.value == "HealthiestYou") iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
                            if (info && info.value == "Term Life") iconProps = { icon: "term-life", fill: "#1B75BC" };
                            if (info && info.value == "Vision") iconProps = { icon: "vision", fill: "#1C4C99" };
                            if (info && info.value == "Accident") iconProps = { icon: "accident", fill: "#F7971C" };
                            if (info && info.value == "Critical Illness") iconProps = { icon: "critical-illness", fill: "#77C3FF" };
                            if (plan.plan_name.value == "Aspire Dental") iconProps = { icon: "aspire-dental", fill: "#77C3FF" };


                            return <Panel showArrow={false} key={plan.id} header={
                                <Container className={css.planData}>
                                    <Container className={css.icon}>
                                        <Icon {...iconProps} />
                                    </Container>
                                    <Container className={css.planDataInfo}>
                                        <Container className={css.carrierName} key={"f" + plan.id}>{plan.carrier.value}</Container>

                                        <Container className={css.planName} key={"f" + plan.id}>{plan.plan_name.value}</Container>
                                    </Container>
                                    <Container className={css.premium} key={"f" + plan.id}>$ {plan.premium.value?.toFixed(2)}</Container>
                                </Container>
                            }>
                                <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <OfferCard hideHeader {...plan} />
                                </Container>
                            </Panel>
                        })
                    }
                    </Collapse>

                </Container>}
                {selectedItems && [...selectedItems].some(s => dentalPlans.some(d => d.id == "" + s)) && <Container style={{ marginBottom: "12px" }} className={css.planTypeContainer}>
                    <Container className={css.planType}>Dental</Container>
                    <Collapse ghost>
                        {
                            [...selectedItems].map(s => {
                                const plan = dentalPlans.filter(f => f.id == "" + s)[0];
                                if (!plan) {
                                    return null;
                                }

                                const { info } = plan;
                                let iconProps;

                                if (info && info.label == "Metal Level") iconProps = { icon: "star-flag", fill: metalLevelColors[info.value] };
                                if (info && info.value == "Association") iconProps = { icon: "association", fill: "#F7971C" };
                                if (info && info.value == "NHICSupplemental") iconProps = { icon: "shield-filled", fill: "#F7971C" };
                                if (info && info.value == "STM") iconProps = { icon: "shield-outlined", fill: "#F7971C" };
                                if (info && info.value == "Basic") iconProps = { icon: "s-outlined", fill: "#1B75BC" };
                                if (info && info.value == "Crown") iconProps = { icon: "crown", fill: "#1B75BC" };
                                if (info && info.value == "Enhanced") iconProps = { icon: "s-filled", fill: "#1B75BC" };
                                if (info && info.value == "Short Term Health Insurance") iconProps = { fill: "#575988", icon: "short-term" };
                                if (info && info.value == "Dental Insurance") iconProps = { icon: "dental-insurance", fill: "#053555" };
                                if (info && info.value == "HealthiestYou") iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
                                if (info && info.value == "Hospital Indemnity") iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
                                if (info && info.value == "Supplemental Indemnity") iconProps = { icon: "supplemental", fill: "#F3BF38" };
                                if (info && info.value == "HealthiestYou") iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
                                if (info && info.value == "Term Life") iconProps = { icon: "term-life", fill: "#1B75BC" };
                                if (info && info.value == "Vision") iconProps = { icon: "vision", fill: "#1C4C99" };
                                if (info && info.value == "Accident") iconProps = { icon: "accident", fill: "#F7971C" };
                                if (info && info.value == "Critical Illness") iconProps = { icon: "critical-illness", fill: "#77C3FF" };
                                if (plan.plan_name.value == "Aspire Dental") iconProps = { icon: "aspire-dental", fill: "#77C3FF" };

                                return <Panel showArrow={false} key={plan.id} header={
                                    <Container className={css.planData}>
                                        <Container className={css.icon}>
                                            <Icon {...iconProps} />
                                        </Container>
                                        <Container className={css.planDataInfo}>
                                            <Container className={css.carrierName} key={"f" + plan.id}>{plan.carrier.value}</Container>

                                            <Container className={css.planName} key={"f" + plan.id}>{plan.plan_name.value}</Container>
                                        </Container>
                                        <Container className={css.premium} key={"f" + plan.id}>$ {plan.premium.value?.toFixed(2)}</Container>
                                    </Container>
                                }>
                                    <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <OfferCard hideHeader {...plan} />
                                    </Container>
                                </Panel>
                            })
                        }</Collapse>
                </Container>}
                {<Container style={{ position: "absolute", bottom: "0px", width: "100%", padding: "0px 36px" }} className={css.totalPremiumContainer}>

                    <Container className={css.totalPremium}>Total premium</Container>
                    <Container className={`${css.totalPremiumValue}`}>$ {results.plans["ALL"].filter(f => selectedItems.has(f.id)).reduce((a, b) => { return a + b.premium.value }, 0).toFixed(2)}</Container>

                </Container>}
            </Container>

        </Container>
    </Container>
    }
        <Container className={css.thirdStep}>
            <Container className={css.finalForm}>
                <Container className={css.cardHeader}>
                    Contact Info
                </Container>
                <Container className={css.toEnroll}>
                    We will contact you and help to enroll the best option(s).
                                </Container>
                <Container className={css.finalFormInputs}>
                    <TextInput value={name} onChange={(e) => { setName(handleInputChange(e)) }} containerclassname={css.finalFormInputText} before="Name :" />
                    <TextInput value={email} onChange={(e) => { setEmail(handleInputChange(e)) }} containerclassname={css.finalFormInputText} before="Email :" />
                    <TextInput value={phone} onChange={(e) => { setPhone(handleInputChange(e)) }} containerclassname={css.finalFormInputText} before="Phone :" />
                    <Container className={css.sendButton}>
                        <AntdButton disabled={!name || !email || !phone} onClick={() => { sendQuote && sendQuote(name, email, phone) }} type="primary">
                            Submit
                                    </AntdButton>
                    </Container>
                </Container>
            </Container>
        </Container>

    </>
}