import React, { useState } from "react"
import { Select } from "antd"
import css from "./style.module.scss"

import { Container, RightOutlined } from "../"
import { v4 as uuid } from "uuid"

export const { Option: SelectOption } = Select;

export default (props) => {

    const uuidv4 = uuid();

    const [open, setOpen] = useState(false);

    return <Container className={`apollo-select ${css.apolloSelect} ${css.relative} ${props.left ? "apollo-select-left" : ""}`}>
        <Select onDropdownVisibleChange={(o) => {
            setOpen(o)
        }} open={open} id={uuidv4} suffixIcon={() => <RightOutlined className={css.icon} />} dropdownClassName={css.dropdown}  {...props} />
        {
            props.before && <Container onPress={() => {
                setOpen(true)
            }} className={`${css.before} ${props.before && props.value !== undefined ? css.active : ""}`}>
                {props.before}
            </Container>
        }
    </Container>
}