import React, { useState } from "react"
import { Accordeon, FilePdfOutlined, Container, Icon, DownOutlined, UpOutlined } from "../../../../"
import css from "./style.module.scss"
import { Table } from "antd"

import { Button } from "antd"
import InOut from "./in_out"

import Details from "./details"

const { Column, ColumnGroup } = Table;

export default (props) => {
    if (props.benefits && props.benefits.length > 0) {

        const [benefitsVisible, setBenefitsVisible] = useState(props.pdf)

        let Legend = () => null;
        let Content = () => null;

        if (props.benefits[0].type == "BENEFIT_IN_OUT") {
            return <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
                <InOut benefits={props.benefits} />
            </div>

        } else if (props.benefits[0].type == "BENEFIT_DETAILS") {

            return <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
                <Details benefits={props.benefits} />
            </div>

        } else {

            Legend = () => <legend onClick={() => {
                setBenefitsVisible(!benefitsVisible)
            }} >Document {<FilePdfOutlined />}</legend>


        }


        return <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
            <Legend />
            <Content />

        </div>
    }
    else return null
}