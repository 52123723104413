import React, { useState } from "react"
import { Accordeon, FilePdfOutlined, Container, Icon, DownOutlined, UpOutlined } from "../../../../.."

import { Table } from "antd"

import { Button } from "antd"

import css from "../style.module.scss"

const { Column } = Table;

export default (props) => {

    const [benefitsVisible, setBenefitsVisible] = useState(false);

    return <Container>
        {
            benefitsVisible ? <Button className={css.bnButton} type="primary" onClick={() => {
                setBenefitsVisible(!benefitsVisible)
            }} > Show Less </Button> : <Button className={css.bnButton} type="primary" onClick={() => {
                setBenefitsVisible(!benefitsVisible)
            }} > <Icon style={{ width: "18px", height: "18px" }} icon="corn-check" fill="#fff" /> Benefits </Button>
        }

        {benefitsVisible && <Table onHeaderRow={() => {
            return {
                className: css.header
            }
        }
        }
            onRow={(record, index) => {
                return {
                    id: record.name
                }
            }
            }
            rowClassName={css.benefitsRow} dataSource={props.benefits.map(b => ({ ...b, key: b.name }))} pagination={false}
            expandIconColumnIndex={4}
            expandRowByClick={true}
            expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                    <UpOutlined className={css.chevronUp} onClick={e => { onExpand(record, null) }} />
                ) : (
                        <DownOutlined className={css.chevronDown} onClick={e => { onExpand(record, null) }} />
                    )
            }


            expandable={false && {
                expandedRowRender: record =>
                    <Container className={css.expandedBenefit}>
                        <Container className={css.expandedBenefitBackground} />
                        <Container className={css.expandedBenefitRow}>
                            <Container className={css.expandedBenefitLabel}>Description</Container>
                            <Container className={css.expandedBenefitDescription}>{record.description}</Container>
                        </Container>
                    </Container>
            }}
        >

            <Column width="55%" className={css.planNameColumn} dataIndex="name" key="name" title={() => <Container className={css.benefitsHeadr}></Container>} render={(text, record) => <>
                <Container className={css.planNameContainer}>
                    {!props.pdf && <Container className={css.planNameIcon}>
                        <svg className={css.cornCheck}>
                            <use xlinkHref="/sprite.svg#corn-check" />
                        </svg>
                    </Container>}
                    <Container className={css.planWithDetails} >
                        <Container className={css.planDetailsName}>{text} </Container> <Container className={css.planDetails}>{record.details}</Container>
                    </Container>
                </Container>

            </>} />

        </Table>
        }
    </Container>
}