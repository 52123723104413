import React from "react"

import "./style.scss"

export * from "@ant-design/icons";
export default ({ className, icon, fill, style = { maxWidth: "100%", maxHeight: "100%" } }) => <svg style={style}
    className={className} >

    <use style={{ fill }} xlinkHref={"/sprite.svg#" + icon}>

    </use>
</svg >;