import React, { useState } from "react";
import { Checkbox, Container, Select, SelectOption, ManOutlined, WomanOutlined, TextInput } from "../../";
import { Button as AntdButton } from "antd"

import { handleInputChange } from "../../../utils"

import css from "./style.module.scss";
import { CheckOutlined, CloseOutlined, EditFilled, EditOutlined, EditTwoTone, SendOutlined } from "../../icon";

export default (props) => {

    const [age, setAge] = useState(props.age);
    const [gender, setGender] = useState(props.gender);
    const [tobacco, setTobacco] = useState(props.tobacco);


    return <Container style={{ minWidth: "200px" }}>
        <Container className={css.inputContainer}>
            <Container onPress={() => { props.hide && props.hide() }} className={`${css.closeButton}`}>
                <CloseOutlined />
            </Container>
            <Container className="flex1">
                <Select placeholder="Gender" size="large" value={gender} allowClear showArrow={!gender} onChange={setGender} dropdownStyle={{ background: "#fff" }}>
                    <SelectOption className={`${css.bgWhite}`} value="Male">
                        <Container className={`${css.genderInputMaleContainer} ${gender === "Male" ? css.maleActive : ""}`}>
                            Male
                            <ManOutlined />
                        </Container>

                    </SelectOption>
                    <SelectOption className={css.bgWhite} value="Female">
                        <Container className={`${css.genderInputFemaleContainer} ${gender === "Female" ? css.femaleActive : ""}`}>
                            Female
                            <WomanOutlined />
                        </Container>
                    </SelectOption>
                </Select>
            </Container>
        </Container>
        <TextInput value={age} onChange={(event) => { setAge(handleInputChange(event)) }} type="number" placeholder="Age" />
        {
            <Container className={css.tobaccoContainer}>

                <Select value={tobacco} size="large" before="Uses Tobacco" onChange={(e) => { setTobacco(e) }} options={[{
                    label: "No",
                    value: false,
                }, {
                    label: "Yes",
                    value: true,
                }

                ]} />
            </Container>}
        <Container className="centered" style={{ marginTop: "12px" }}>
            {
                props.gender ?
                    <Container style={{ display: "flex" }}>
                        <AntdButton className={`${css.button} grid-margin-right`} danger type="primary" onClick={() => { props.onSubmit && props.onSubmit(null) }}>
                            REMOVE
                            <CloseOutlined />
                        </AntdButton>
                        <AntdButton className={`${css.button} ${css.updateButton} grid-margin-left`} type="primary" onClick={() => { props.onSubmit && props.onSubmit({ age, gender }) }}>
                            UPDATE
                            <CheckOutlined />
                        </AntdButton>
                    </Container>
                    :
                    <AntdButton disabled={!age || !gender || tobacco === undefined} className={`${css.button}`} type="primary" onClick={() => { props.onSubmit && props.onSubmit({ age, gender }) }}>
                        ADD
            </AntdButton>
            }
        </Container>
    </Container >
}