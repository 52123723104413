import React, { useState } from "react";

import { Container, DownOutlined, UpOutlined } from '../'

import css from "./style.module.scss";

export default (props) => {


    const [collapsed, setCollapsed] = useState(!props.defaultOpen);


    if (props.collapsed !== undefined) {

        return <Container className={`${css.accordeonContainer} ${props.collapsed ? css.accordeonCollapsed : ''}`}>
            <Container onPress={() => { props.setCollapsed(!props.collapsed) }} className={css.accordeonHeader}>
                <Container>
                    {props.title}
                </Container>
                <Container>
                    {props.collapsed ? <DownOutlined /> : <UpOutlined />}
                </Container>
            </Container>
            {!props.collapsed && props.children}
        </Container>
    }

    else return <Container className={`${css.accordeonContainer} ${collapsed ? css.accordeonCollapsed : ''}`}>
        <Container onPress={() => { setCollapsed(!collapsed) }} className={css.accordeonHeader}>
            <Container>
                {props.title}
            </Container>
            <Container>
                {collapsed ? <DownOutlined /> : <UpOutlined />}
            </Container>
        </Container>
        {!collapsed && props.children}
    </Container>
}