import React, { useState, useRef } from "react"
import css from "./style.module.scss";

import { Input } from "antd";

import { Container } from ".."

export default (props) => {
    const [focused, setFocused] = useState(false)
    const ref = useRef(null);

    return <Container className={`${css.relative} ${props.className}`}>
        <Input ref={ref} onFocus={() => { setFocused(true) }} onBlur={() => { setFocused(false) }} className={`${css.apolloInput} ${props.before ? css.end : ""}`} {...props} />
        {(focused || (props.value && props.value != "")) && <Container className={css.placeholder}>
            {props.focusedlabel}
        </Container>
        }

        {
            props.before && <Container onPress={() => { console.log("ref", ref); ref.current.focus() }} className={`${css.before} ${props.before && props.value ? css.active : ""}`}>
                {props.before}
            </Container>
        }
    </ Container >
}