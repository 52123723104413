import React from "react";
import ReactDOM from "react-dom";

import { Container, CloseOutlined } from ".."

import css from "./style.module.scss"

export const PopupRaw = (props: { className, left?: string | React.Node; title?: string | React.ReactNode, children: any; onClose(), visible: boolean }) => props.visible ?
    <Container className={css.popupContainer}>
        <Container className={`${css.popupInnerContainer} ${props.className}`}>
            {props.title && <Container className={css.popupHeader}>
                {props.left || <Container />}
                <Container>{props.title}</Container>
                <CloseOutlined onClick={props.onClose.bind(this, true)} />
            </Container>}
            <Container className={css.popupContent}>
                {props.children}
            </Container>
        </Container>
    </Container> : null;

export default (props) => {
    try {
        return ReactDOM.createPortal(<PopupRaw {...props} />, document.getElementById("apollo-popup"))
    } catch (e) {
        return null;
    }
}