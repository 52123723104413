import React from "react"
import { Container } from "..";
import css from './style.module.scss'

export default (props) =>
    <Container {...props} className={`${css.avatarContainer} ${props.className}`}>
        <Container className={css.avatarInnerContainer}>
            <img className={`${props.round ? css.round : ''} ${props.border ? css.border : ''}`} src={props.src} />
        </Container>
    </Container>
