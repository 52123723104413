import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid"
import _ from "lodash";

import axios from "axios";
import css from './style.module.scss'
import { Button as AntdButton, Alert, Tabs, Result, Steps, Button } from 'antd';
import { Container, TextInput, QuotesForm } from "../";
import { FilePdfOutlined, FilterOutlined } from "../"
import { OfferCardList, QuotesPanelProfile, SearchFilters } from "./components"

import datasourcesmock from "./temp/datasources"

import { exportPDF } from "./pdf-util"
import { CheckOutlined, FormOutlined } from "../icon";
import { connect } from "react-redux";

import FinishStep from "./components/finish-step"


const handleInputChange = event => {
    const target = event.target
    const value = target.value
    return value;
}

const { TabPane } = Tabs;

const { Step } = Steps;

const steps = [
    {
        title: "Profile"
    },
    {
        title: "Cart"
    },
    {
        title: "Finish",
    }
]

const QuotesPanelRaw = (props) => {
    try {
        (function (history: any) {
            var pushState = history.pushState;
            history.pushState = function (state) {
                if (typeof history.onpushstate == "function") {
                    history.onpushstate({ state: state });
                }

                return pushState.apply(history, arguments);
            };
        })(window.history);
    } catch (e) {

    }

    const siteData = props.data;

    let getParams = () => {
        try {
            return document.location.search.substring(1).split("&").reduce((old, current) => { const [key, value] = current.split("="); value && (old[key] = value.replace("%20", " ")); return old }, {});
        } catch (e) {
            return {}
        }
    }

    const agent = {
        id: props.data.site.siteMetadata.agent.id,
        name: props.data.site.siteMetadata.agent.name,
        title: props.data.site.siteMetadata.agent.title,
        email: props.data.site.siteMetadata.agent.email,
        website: props.data.site.siteMetadata.agent.website,
        phone: props.data.site.siteMetadata.agent.phone,
        image: props.data.site.siteMetadata.agent.assets.image
    }


    const [results, setResults]: any[] = useState()
    const [mobileActiveContainer, setMobileActiveContainer] = useState("results")
    const [activeTab, setActiveTab] = useState(`Marketplace`)

    const [state, setNewState] = useState({ error: false, lastParams: null, loading: true, lastResults: null, params: getParams() });
    const [error, setError] = useState(false);
    const [datasources] = useState(datasourcesmock)
    const [currentState, setCurrentState] = useState(1);
    const [selectedItems, updateSelectedItems] = useState(new Set());

    const [name, setName] = useState()

    const [email, setEmail] = useState()

    const [phone, setPhone] = useState()
    const [questions, setQuestions] = useState({});
    let openRecommendations;

    const applyFilters = (items, carrier) => {


        const carrierFilters = props.filters[carrier];

        let filtered = items;

        if (carrierFilters) {

            Object.keys(carrierFilters).forEach(k => {
                if (k == "CHECKBOX") {

                    const attributes = Object.keys(carrierFilters[k])

                    attributes.forEach(a => {
                        const attributeValue = carrierFilters[k][a];

                        if (!attributeValue || attributeValue.size == 0) {
                            return;
                        }

                        const planMap = a.split(".");
                        filtered = items.filter(planValue => {

                            planMap.forEach(planKey => {
                                planValue = planValue[planKey]
                            })

                            return Array.from(attributeValue).includes(planValue)
                        })
                    })
                }
            })
        }

        const generalFilters = props.filters["ALL"];

        if (generalFilters) {


            Object.keys(generalFilters).forEach(k => {
                if (k == "MIN_MAX_SLIDER") {
                    const attributes = Object.keys(generalFilters[k])

                    attributes.forEach(a => {

                        let minMax = generalFilters[k][a];

                        if (minMax) {
                            const planMap = a.split(".");

                            filtered = filtered.filter(planValue => {


                                planMap.forEach(planKey => {
                                    planValue = planValue[planKey]
                                })


                                return planValue >= minMax.min && planValue <= minMax.max
                            })


                        }
                    })
                }
            })
        }


        const { by: sortBy, order } = props.sort

        if (sortBy === "Premium") {
            filtered = filtered.sort((a, b) => a.premium.value >= b.premium.value ? 1 : -1)
        }

        if (sortBy === "Deductible") {
            filtered = filtered.sort((a, b) => a.deductible.value >= b.deductible.value ? 1 : -1)
        }


        if (order == "desc") {
            filtered = filtered.reverse();
        }
        return filtered;

    }

    const onOffercardSelected = (value) => {
        const newSelectedItems = new Set([...selectedItems]);
        value.checked ? newSelectedItems.add(value.id) : newSelectedItems.delete(value.id);
        updateSelectedItems(newSelectedItems);
    }

    const fetchQuotes = async (attributes) => {
        let r = [];

        const propKeys = Object.keys(attributes).filter(f => datasources[1].inputs.map(m => m.name).includes(f))
        let data: any = {};

        propKeys.map(pk => {
            const type = datasources[1].inputs.filter(f => f.name == pk)[0]
            data[pk] = type.type === "number" ? Number(attributes[pk]) : attributes[pk]
            data[pk] = type.type === "object" || type.type === "array" ? JSON.parse(decodeURI(attributes[pk])) : attributes[pk]
        })

        try {
            const response = await axios({
                method: 'post',
                url: `https://qa.api.myaspirequotes.qa.apolloquotes.com/v1/plans/`,
                data,
                headers: {
                    "X-Apollo-Quotes-Source": props.data.site.siteMetadata.agent.id
                }
            })

            const MARKETPLACE = response.data.plans[0].MARKETPLACE.map(
                (r) => {
                    return {
                        ...r,
                        benefits: r.benefits.sort((a, b) => { return a.name > b.name ? 1 : -1 }),
                        id: uuid()
                    }
                }

            );

            const NATIONAL_GENERAL = response.data.plans[1].NATIONAL_GENERAL.map(r => {
                return {
                    ...r,
                    benefits: []
                    , id: uuid()
                }
            });;

            const ONE_SHARE = response.data.plans[2].ONE_SHARE.map(r => { return { ...r, id: uuid() } });;

            const ASPIRE_DENTAL = response.data.plans[3].ASPIRE_DENTAL.map(r => { return { ...r, id: uuid() } });;

            const UNITED_HEALTHCARE = response.data.plans[4].UNITED_HEALTHCARE.map(r => { return { ...r, id: uuid() } });;

            response.data.plans = {
                MARKETPLACE,
                NATIONAL_GENERAL,
                ONE_SHARE,
                ASPIRE_DENTAL,
                UNITED_HEALTHCARE,
                ALL: [...MARKETPLACE, ...NATIONAL_GENERAL, ...ONE_SHARE, ...ASPIRE_DENTAL, ...UNITED_HEALTHCARE],
                MEDICAL: [...MARKETPLACE, ...NATIONAL_GENERAL, ...ONE_SHARE, ...UNITED_HEALTHCARE],
                DENTAL: [...ASPIRE_DENTAL]
            }

            response.data.facets[1].values[0].values = response.data.facets[1].values[0].values.map(m => ({
                ...m,
                badge: {
                    color: m.badge.color,
                    icon: "star-flag"
                }
            }))

            response.data.facets[2].values[0].values = response.data.facets[2].values[0].values.map(m => ({
                ...m,
                badge: {
                    color: "#F7971C",
                    icon: m.label == "NHICSupplemental" ? "shield-filled" : m.label == "STM" ? "shield-outlined" : "association"
                }
            }))

            response.data.facets[3].values[0].values = response.data.facets[3].values[0].values.map(m => ({
                ...m,
                badge: {
                    color: "#1B75BC",
                    icon: m.label == "Basic" ? "s-outlined" : m.label == "Crown" ? "crown" : "s-filled"
                }
            }))


            response.data.facets[4].values[0].values = response.data.facets[4].values[0].values.map(m => ({
                ...m,
                badge:
                    m.label == "Accident" ? {
                        color: "#F7971C",
                        icon: "accident",
                    } :
                        m.label == "Dental Insurance" ? {
                            color: "#053555",
                            icon: "dental-insurance"
                        } :
                            m.label == "HealthiestYou" ? {
                                color: "#77C3FF",
                                icon: "healthiest-you"
                            } :
                                m.label == "Hospital Indemnity" ? {
                                    color: "#FF3D3D",
                                    icon: "hospital-indemnity"
                                } :
                                    m.label == "Short Term Health Insurance" ? {
                                        color: "#575988",
                                        icon: "short-term",
                                        mask: "url(#ra)"
                                    } :
                                        m.label == "Term Life" ? {
                                            color: "#2089E9",
                                            icon: "term-life"
                                        } :
                                            m.label == "Supplemental Indemnity" ? {
                                                color: "#F3BF38",
                                                icon: "supplemental"
                                            } :
                                                m.label == "Vision" ? {
                                                    color: "#1C4C99",
                                                    icon: "vision"
                                                } : m.label == "Critical Illness" ? {
                                                    color: "#77C3FF",
                                                    icon: "critical-illness"
                                                } : {}

            }))



            r.push({ ...datasources[0], response })




            return r;
        } catch (error) {
            setError(true);
            return { error: true }
        }
    }

    try {
        window.onpopstate = (window as any).onpushstate = (e) => {
            setResults();
            setError(false);

            const params = getParams();
            setNewState({
                params,
                loading: true,
                error: false,
                lastParams: state.lastParams,
                lastResults: results
            })
        };
    } catch (e) {

    }

    useEffect(() => {
        const params = document.location.search.substring(1).split("&").reduce((old, current) => { const [key, value] = current.split("="); value && (old[key] = value.replace("%20", " ")); return old }, {})

        fetchQuotes(params).then((r: any) => {
            if (r && r.error) {
                return;
            }
            if (!_.isEqual(r, results)) {
                setResults(
                    r[0].response.data,
                )
            }
        })
    }, [state, props.filters])


    const sendQuote = async (name, email, phone) => {

        const data = {
            user_name: name,
            user_email: email,
            user_phone: phone,
            questions
        }

        exportPDF(results.plans["ALL"].filter(f => selectedItems.has(f.id)), agent, data, results.uuid);

        setCurrentState(3);
    }

    return (
        <>
            {selectedItems && currentState == 1 && (selectedItems.size > 0 || !_.isEmpty(questions)) && <Container onPress={() => { setCurrentState(2) }} className={css.floatButton}>
                <Container onPress={() => { setCurrentState(2) }} className={css.mobileMenuOption}>
                    <svg>
                        <use xlinkHref="/sprite.svg#finalize" />
                    </svg>

                                Finish
                            </Container>
            </Container>}
            <Container className={css.mainSearchContainer}>
                {
                    currentState == 0 && <Container className={css.profileContainer}>


                        <Container className={css.quotesFormContainer}>

                            <Container className={css.stepsContainer}>
                                <Steps size="small" className={css.steps} current={
                                    currentState
                                }
                                    type="navigation"
                                    onChange={(e) => { setCurrentState(e) }}
                                >
                                    {steps.map((item, pos) => (
                                        <Step key={"step" + pos + "an"} disabled={(pos == 1 && !results) || (pos == 2 && selectedItems.size == 0)} title={item.title} />
                                    ))}
                                </Steps>
                            </Container>
                            <Container className={css.quotesFormInner}>
                                <QuotesForm {...getParams()} onSubmit={() => {
                                    setResults();
                                    setError(false);
                                    setCurrentState(1);
                                    updateSelectedItems(new Set())
                                    const params = getParams();
                                    setNewState({
                                        params,
                                        loading: true,
                                        error: false,
                                        lastParams: state.lastParams,
                                        lastResults: results
                                    })
                                }} />
                            </Container>
                        </Container>
                    </Container>
                }
                {
                    !error && currentState == 1 && <Container className={`${mobileActiveContainer !== "filters" ? "hidden-on-mobile" : ""}`}>
                        {results && <SearchFilters activeTab={activeTab} data={results.facets} />}
                        <Container className={css.applyFilterButton} onPress={() => { setMobileActiveContainer("results") }}>

                            <Button shape="round" type="primary">
                                Go Back
                            </Button>
                        </Container>
                    </Container>
                }



                {
                    currentState == 1 && <Container className={`${css.cardListContainer} ${mobileActiveContainer !== "results" ? "hidden-on-mobile" : ""}`}>
                        {
                            !error && <Container onPress={() => { openRecommendations && openRecommendations() }} className={`hidden-on-web ${css.recommendPlanButton}`}>
                                {_.isEmpty(questions) ? <>Have {siteData?.site?.siteMetadata?.agent?.name} recommended a plan</> : <><FormOutlined />Edit Answers </>}
                            </Container>
                        }
                        <Container className={css.stepsContainer}>
                            <Steps size="small" className={css.steps} current={
                                currentState
                            }
                                type="navigation"
                                onChange={(e) => { setCurrentState(e) }}
                            >
                                {steps.map((item, pos) => (
                                    <Step key={"step" + pos + "a"} status={pos == 1 && currentState == 1 && error ? "error" : undefined} disabled={pos == 2 && selectedItems.size == 0 && _.isEmpty(questions)} title={item.title} />
                                ))}
                            </Steps>
                        </Container>

                        {
                            error &&
                            <Container className={css.errorCardContainer}>
                                <Alert
                                    message="Ops, there was an error"
                                    showIcon
                                    description="Please try again in a second and if the error persist change the data"
                                    type="error"
                                    className={css.errorCard}

                                /></Container>
                        }
                        {!error &&
                            <Tabs tabBarGutter={-18} className={css.tab} renderTabBar={(props, DefaultTabBar) => {
                                return <Container className={css.tabBarContainer}>
                                    <DefaultTabBar {...props} />
                                </Container>
                            }} tabBarStyle={{ background: "#fff", maxWidth: "100%", paddingLeft: "2vw" }} onChange={setActiveTab} activeKey={activeTab} centered  >
                                <TabPane tab={`Marketplace ${results ? "(" + applyFilters(results.plans["MARKETPLACE"], "MARKETPLACE").length + ")" : ""}`} key={`Marketplace`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_cod
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["MARKETPLACE"], "MARKETPLACE").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`National General ${results ? "(" + applyFilters(results.plans["NATIONAL_GENERAL"], "NATIONAL_GENERAL").length + ")" : ""}`} key={`National General`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["NATIONAL_GENERAL"], "NATIONAL_GENERAL").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`One Share ${results ? "(" + applyFilters(results.plans["ONE_SHARE"], "ONE_SHARE").length + ")" : ""}`} key={`OneShare`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["ONE_SHARE"], "ONE_SHARE").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`UHC ${results ? "(" + applyFilters(results.plans["UNITED_HEALTHCARE"], "UNITED_HEALTHCARE").length + ")" : ""}`} key={`United Healthcare`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["UNITED_HEALTHCARE"], "UNITED_HEALTHCARE").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`Aspire Dental ${results ? "(" + applyFilters(results.plans["ASPIRE_DENTAL"], "ASPIRE_DENTAL").length + ")" : ""}`} key={`Aspire Dental`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["ASPIRE_DENTAL"], "ASPIRE_DENTAL").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                            </Tabs>
                        }
                        <Container className={`${css.mobileMenuContainer}`}>
                            <Container className={css.mobileMenu}>

                                <Container onPress={() => { setMobileActiveContainer("filters") }} className={css.mobileMenuOption}>
                                    <svg>
                                        <use xlinkHref="/sprite.svg#filters" />
                                    </svg>

                                Filter
                            </Container>


                                {selectedItems && currentState == 1 && (selectedItems.size > 0 || !_.isEmpty(questions)) && <Container onPress={() => { setCurrentState(2) }} className={css.mobileMenuOption}>
                                    <svg>
                                        <use xlinkHref="/sprite.svg#finalize" />
                                    </svg>

                                Finish
                            </Container>}
                            </Container>
                        </Container>
                    </Container>
                }
                {
                    currentState == 2 && <Container className={css.profileContainer}>

                        <Container className={css.quotesFormContainer}>
                            <Container className={css.stepsContainer}>
                                <Steps size="small" className={css.steps} current={
                                    currentState
                                }
                                    type="navigation"
                                    onChange={(e) => { setCurrentState(e) }}
                                >
                                    {steps.map((item, pos) => (
                                        <Step key={"stepsz" + pos} title={item.title} />
                                    ))}
                                </Steps>
                            </Container>
                            <FinishStep sendQuote={sendQuote} results={results} selectedItems={selectedItems} />
                        </Container>
                    </Container>
                }
                {
                    currentState == 3 && <Container className={css.profileContainer}>

                        <Container className={css.quotesFormContainer}>
                            <Result
                                status="success"
                                title="Thank you for submitting a request with ApolloQuotes!"
                                subTitle="Please allow your Apollo agent time to review your request to follow up with more details about the right plan for you."
                                extra={[
                                    <AntdButton onClick={() => { exportPDF(results.plans["ALL"].filter(f => selectedItems.has(f.id)), { ...agent, questions }) }} danger type="primary" key="downloadpdf">
                                        Download PDF
                                        <FilePdfOutlined />
                                    </AntdButton>,
                                    <AntdButton onClick={
                                        () => {
                                            setCurrentState(0);
                                        }
                                    } type="primary" key="newquote">Quote again</AntdButton>,
                                ]}
                            />

                        </Container>
                    </Container>
                }
                <QuotesPanelProfile openRecommendations={(f) => { openRecommendations = f }} questions={questions} onSave={(q) => { setQuestions(q) }} enabled={results} onPicwellOn={() => {
                    const tempR = { ...results };

                    tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map((res, index) => {
                        return { ...res, score: (100 / tempR.plans.MARKETPLACE.length) * (index + 1) }
                    })
                    setResults(
                        tempR
                    )
                }

                }

                    onPicwellOff={() => {
                        const tempR = { ...results };

                        tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map((res, index) => {
                            return { ...res, score: null }
                        })

                        setResults(
                            tempR
                        )
                    }}

                    data={siteData} mobileActiveContainer={mobileActiveContainer} />
            </Container>
            <Container id="pdf" />

        </>
    )
}

const mapStateToProps = ({ filters, results, sort }) => {
    return {
        filters: { ...filters },
        results: { ...results },
        sort: { ...sort },
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchQuotes: (payload) => dispatch({ type: `FETCH_QUOTES`, payload }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesPanelRaw)

export {
    OfferCardList,
    SearchFilters
}