import React from "react";

import { Badge, Container, Checkbox } from "./../../../../.."

import css from "./style.module.scss"
import { connect } from "react-redux"


const mapStateToProps = ({ filters }) => {
    return { filters: { ...filters } }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addFilter: (payload) => dispatch({ type: `ADD_FILTER`, payload }),
        removeFilter: (payload) => dispatch({ type: `REMOVE_FILTER`, payload }),
    }
}

const CheckboxFilterGroupRaw = (props) => {

    const { carrierKey } = props;

    const carrierFilters = props.filters[carrierKey]["CHECKBOX"] || new Set();

    const values = carrierFilters;


    return props.values.map(item => {

        const attList = values[item.attribute] ? [...values[item.attribute]] : []

        return <Container className={`${css.checkBoxGroupContainer}`} key={"l" + item.label}>
            <Checkbox key={"check" + item.label} checked={attList.includes(item.label)} onChange={
                (e) => {
                    //setItemValue(value.target.checked);
                    if (e.target.checked) {
                        props.addFilter({
                            carrierKey, item, value: item.label
                        }
                        )
                    } else {
                        props.removeFilter({
                            carrierKey, item, value: item.label
                        })
                    }
                }} />
            <Container className={`${css.flex} ${css.checkBoxLabel}`}>
                {item.label} {item.badge && <Badge style={{ height: "24px", padding: "2px", marginLeft: "4px", marginTop: "-1px" }} mask={item.badge.mask} icon={item.badge.icon} color={item.badge.color} />}
            </Container>
        </Container>
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxFilterGroupRaw)