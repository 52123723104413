import React, { useState, useEffect } from "react";

import { Button, Container, Popup, TextArea, TextInput, Select, CloseOutlined } from "../../../"

import css from "./style.module.scss"
import { handleInputChange } from "../../../../utils"

export default (props) => {

    const MapQuestion = (q) => {

        let Input = () => null;


        if (q.type == "text") {
            Input = () => <TextInput id={"question" + q.id} key={q.question + "input"} />
        } else if (q.type == "select") {
            Input = () => <Select defaultValue={
                { value: props["question" + q.id]?.value }
            } labelInValue id={"question" + q.id} left options={
                q.options.map(o => {
                    return {
                        label: o.text,
                        value: o.value
                    }
                })
            } />
        } else if (q.type == "text-area") {
            Input = () => <TextArea id={"question" + q.id} rows={4} />;
        }


        return <Container key={q.question + "question"} className={css.questionContainer}>
            <Container className={css.question}>
                {q.question}
            </Container>
            <Container className={css.input}>
                <Input />
            </Container>
        </Container>
    }



    const questions = [{
        id: 1,
        question: "About how often do you see a doctor during the year?",
        options: [
            { value: "Every month", text: "Every month" },
            { value: "Once a quarter", text: "Once a quarter" },
            { value: "Each six months", text: "Each six months" },
            { value: "I didn't visit in the last year", text: "I didn't visit in the last years" },
        ],
        type: "select",
    },

    {
        id: 3,
        question: "Why are you looking for a new plan?",

        type: "text",
    },
    {
        id: 2,
        question: "Do you have any preexisting medical conditions?",
        placeholder: "Describe your conditions or leave blank",
        type: "text",
    },

    {
        id: 4,
        question: "Other comments:",
        type: "text-area",
    },
    ];


    const getSelectValue = (id) => {
        const list = document.getElementById(id + "_list");
        let value = props[id]?.value;
        list && list.childNodes.forEach((node: HTMLDivElement) => {
            if (node.getAttribute("aria-selected") + "" == "true") {
                value = node.getAttribute("aria-label");
            }
        })
        return value;
    }

    useEffect(() => {
        if (props.question1) {
            if (document.getElementById("question2") !== null) {
                document.getElementById("question2").value = props.question2.value
            }
        }

        if (props.question3) {
            if (document.getElementById("question3") !== null) {
                document.getElementById("question3").value = props.question3.value
            }
        }


        if (props.question4) {
            if (document.getElementById("question4") !== null) {
                document.getElementById("question4").value = props.question4.value
            }
        }
    })


    return <Popup
        className={css.popupContainer}
        visible={props.visible} onClose={props.onClose}>
        <Container className={css.recommendations}>
            <Container className={css.closeButton}>
                <CloseOutlined onClick={props.onClose} />
            </Container>
            <Container >
                {questions.map((q) => <MapQuestion key={q.id + "a" + q.question} {...q} />)}
            </Container>
            <Container onPress={() => {
                props.onSave && props.onSave({
                    question1: {
                        question: questions[0].question,
                        value: getSelectValue("question1")
                    },
                    question2: {
                        question: questions[1].question,
                        value: document.getElementById("question2").value
                    },
                    question3: {
                        question: questions[2].question,
                        value: document.getElementById("question3").value
                    },
                    question4: {
                        question: questions[3].question,
                        value: document.getElementById("question4").value
                    }

                })
                props.onClose && props.onClose()
            }} className={css.saveButton}>
                Save
            </Container>
        </Container>
    </Popup>
}