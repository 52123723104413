import React from "react";
import { Badge, Checkbox, Container, ShareAltOutlined, Icon, Tooltip } from "../../..";
import { Skeleton } from "antd";

import BenefitsList from "./benefits-list"

import css from "./style.module.scss";
import { HeartFilled, HeartOutlined } from "../../../icon";

export const metalLevelColors = {
    Bronze: "#6A3805",
    Catastrophic: "#575988",
    Gold: "#AF9500",
    Platinum: "#3B3B41",
    Silver: "#B4B4B4"
}

export const OfferCardSkeleton = () => {
    return <Container className={css.offerCardContainer}>
        <Container style={{ borderColor: "#EEE", padding: "24px" }} className={css.infoContainer} >
            <Skeleton title={false} paragraph={{ rows: 4, width: "100%" }} active />
        </Container>
    </Container>
}

export default ({ benefits, hideHeader, metal_level, innerStyle, style, score, carrier, imgid, exportImage, pdf, onSelectChange, deductible, id, plan_name, premium, selected, info, showSelection }) => {
    let iconProps = {}

    if (info && info.label == "Metal Level") iconProps = { icon: "star-flag", fill: metalLevelColors[info.value] };
    if (info && info.value == "Association") iconProps = { icon: "association", fill: "#F7971C" };
    if (info && info.value == "NHICSupplemental") iconProps = { icon: "shield-filled", fill: "#F7971C" };
    if (info && info.value == "STM") iconProps = { icon: "shield-outlined", fill: "#F7971C" };
    if (info && info.value == "Basic") iconProps = { icon: "s-outlined", fill: "#1B75BC" };
    if (info && info.value == "Crown") iconProps = { icon: "crown", fill: "#1B75BC" };
    if (info && info.value == "Enhanced") iconProps = { icon: "s-filled", fill: "#1B75BC" };
    if (info && info.value == "Short Term Health Insurance") iconProps = { fill: "#575988", icon: "short-term" };
    if (info && info.value == "Dental Insurance") iconProps = { icon: "dental-insurance", fill: "#053555" };
    if (info && info.value == "HealthiestYou") iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
    if (info && info.value == "Hospital Indemnity") iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
    if (info && info.value == "Supplemental Indemnity") iconProps = { icon: "supplemental", fill: "#F3BF38" };
    if (info && info.value == "HealthiestYou") iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
    if (info && info.value == "Term Life") iconProps = { icon: "term-life", fill: "#1B75BC" };
    if (info && info.value == "Vision") iconProps = { icon: "vision", fill: "#1C4C99" };
    if (info && info.value == "Accident") iconProps = { icon: "accident", fill: "#F7971C" };
    if (info && info.value == "Critical Illness") iconProps = { icon: "critical-illness", fill: "#77C3FF" };
    if (plan_name.value == "Aspire Dental") iconProps = { icon: "aspire-dental", fill: "#77C3FF" };
    return <Container style={style} id={imgid} className={`${css.offerCardContainer} ${pdf ? css.pdf : css.screen}`}>
        <Container style={innerStyle} className={`${css.infoContainer} ${pdf ? css.infoContainerScreenOnly : ""} ${score ? score > 50 ? css.green : score > 25 ? css.yellow : css.red : ""}`}>
            {!hideHeader && <Container className={css.planNames}>
                <Tooltip title={carrier.value} >
                    <Container className={css.issuerName}>
                        {carrier.value} {score ? `(score: ${"" + (100 - score)})` : ``}
                    </Container>
                </Tooltip>
                <Container className={css.planName}>
                    <Tooltip title={plan_name.value} >
                        {plan_name.value}
                    </Tooltip>
                </Container>
            </Container>}

            <Container className={css.info2}>
                {!hideHeader && <Icon {...iconProps} />}

                <Container className={`${css.planTypePrice} ${css.marginRight24px}`}>
                    <Container className={css.planCategory} style={{ color: iconProps.fill }} >
                        {info?.value}
                    </Container>

                    <Container className={css.planType}>
                        {premium.label}
                    </Container>
                    <Container className={css.planPrice}>
                        $ {premium.value + " / Month"}
                        {
                            premium.premium_without_subsidy && <Container className={css.premiumWithoutSubsidy}>
                                $ {premium.premium_without_subsidy}
                            </Container>}


                    </Container>
                </Container>

            </Container>
            {!pdf && <Container>
                <BenefitsList pdf={pdf} benefits={benefits} id={imgid} />
            </Container>}
            {
                !pdf && <Container className={css.info3} >
                    {info ? <Container className={css.info3}>
                        {deductible.label}:
                        <Container className={css.metalLevelText}> ${deductible.value}</Container>
                    </Container > : <Container className={css.info3} ><Badge style={{ height: "18px", marginTop: "4px" }} color={"#fff"} /></Container>}

                </Container>
            }
            {!pdf && onSelectChange && <Container onPress={() => {
                onSelectChange && onSelectChange({
                    id,
                    checked: !selected
                })
            }} className={`${css.info4} ${selected ? css.selected : ""}`}>
                Interested
                        {
                    selected ? <HeartFilled /> : <HeartOutlined />
                }
            </Container>}
        </Container>
    </Container>
}
