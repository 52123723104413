import React from "react";
import { Container } from ".."

import css from "./style.module.scss"

export default () => {
    return <Container className={css.logoContainer}>
        <Container className={css.logo}>
            <svg>
                <use xlinkHref="/sprite.svg#apollo" />
            </svg>
        </Container>
        <Container className={css.logoText}>
            <span>Apollo </span> Quotes

            </Container>
    </Container>
};